<template>
  <GiftLayout
    is-christmas
    is-beg-header
  >
    <div class="gift-voicemod gift-voicemod--christmas">
      <section
        v-if="email"
        class="gift-voicemod__pricing"
      >
        <h1
          class="gift-voicemod__title"
          role="title"
        >
          {{ $t('begGifting.title', { email: email }) }}
        </h1>

        <h3 class="gift-voicemod__pricing-title">
          {{ $t('giftVoicemod.planTitle') }}
        </h3>

        <div class="gift-voicemod__pricing-plans">
          <pricing-plan-component
            v-for="plan in plans"
            :key="plan.id"
            :plan="plan"
            :is-selected="isSelected(plan)"
            is-christmas
            @onSelectPlan="handleSelectPlan"
          />

          <base-select
            class="gift-voicemod__pricing-plans-select"
            placeholder="Select a Plan"
            :options="planSelectOptions"
            :value="getSelectedValue"
            @select="handleSelectPlan"
          />
        </div>

        <h3 class="gift-voicemod__faq-title">
          {{ $t('giftVoicemod.howSectionTitle') }}
        </h3>

        <p class="gift-voicemod__faq-description">
          {{ $t('begGifting.text1') }}
        </p>
        <p class="gift-voicemod__faq-description">
          {{ $t('begGifting.text2') }}
        </p>
      </section>

      <section class="gift-voicemod__form">
        <BegFormComponent
          v-if="canShowForm"
          :email="email"
          :selected-plan="getSelectedPlan"
          is-christmas
        />

        <h3 class="gift-voicemod__faq-title">
          {{ $t('giftVoicemod.howSectionTitle') }}
        </h3>
        <p class="gift-voicemod__faq-description">
          {{ $t('begGifting.text1') }}
        </p>
        <p class="gift-voicemod__faq-description">
          {{ $t('begGifting.text2') }}
        </p>
      </section>
    </div>
  </GiftLayout>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { CookieService } from '@/services/cookie';
import { ROUTER_PATHS } from '@/router/paths';
import GiftLayout from '@/components/GiftLayout/GiftLayout.vue';
import PricingPlanComponent from '@/components/PricingPlanComponent/PricingPlanComponent.vue';
import BegFormComponent from '@/components/BegFormComponent/BegFormComponent.vue';
import { metricsService } from '@/metrics/metrics';
import { GIFT_PRODUCT_KEYS, GIFT_KEYS } from '@/domain/user';
import { AccountClient } from '@/services/models/accounts';
import { mParticleMetric } from '../../metrics/mparticle';

export default {
    components: {
        PricingPlanComponent,
        BegFormComponent,
        GiftLayout,
        BaseSelect: () => import(/* webpackChunkName: "BaseSelect" */ '@RepoComponent/BaseSelect/BaseSelect'),
    },
    data() {
        return {
            selectedPlanId: null,
            email: null,
            plans: [],
        };
    },
    computed: {
        planSelectOptions() {
            return this.plans.map((plan) => ({
                label: `${plan.planTranslatedName} - ${plan.fullPrice || plan.price}`,
                value: plan.productId,
            }));
        },
        hasValidSession() {
            return CookieService.getCookie('access_token');
        },
        canShowForm() {
            return this.selectedPlanId !== null;
        },
        getSelectedValue() {
            return this.planSelectOptions.find((option) => option.value === this.selectedPlanId)?.label;
        },
        getSelectedPlan() {
            return this.plans.find((plan) => plan.productId === this.selectedPlanId);
        },
    },
    mounted() {
        const email = this.$route.query.ref;
        if (email) {
            this.email = decodeURIComponent(atob(email));
        }

        if (!this.email) {
            this.$router.push({
                path: ROUTER_PATHS.GIFT,
            });
        }
        this.setPlans();
    },
    methods: {
        async setPlans() {
            try {
                const { data: plans } = await AccountClient.getGiftProducts();
                this.plans = this.addTranslationToPlans(plans);
                this.trackView();
            } catch (error) {
                Sentry.captureMessage('Can\'t retrieve Gift a License products');
                Sentry.captureException(error);
                this.plans = [];
            }
        },
        trackView() {
            const lifePrice = this.plans.find(({ internalEvent }) => internalEvent === GIFT_KEYS.lifetime);
            const threeMonthsPrice = this.plans.find(({ internalEvent }) => internalEvent === GIFT_KEYS.threeMonths);
            const oneYearPrice = this.plans.find(({ internalEvent }) => internalEvent === GIFT_KEYS.oneYear);
            const eventData = {
                step: 'view',
                otl_price: lifePrice.fullPrice || lifePrice.price,
                '3m_price': threeMonthsPrice.fullPrice || threeMonthsPrice.price,
                '1y_price': oneYearPrice.fullPrice || oneYearPrice.price,
                voicemod_system: 'account.voicemod.net',
                is_beg: true,
            };
            metricsService.trackEvent(eventData);
            mParticleMetric.track(
                mParticleMetric.EVENTS.GIFT_LICENSE, eventData, mParticleMetric.EVENT_TYPE.Transaction,
            );
        },
        addTranslationToPlans(plans = []) {
            return plans.map((plan) => ({
                ...plan,
                planTranslatedName: this.$t(GIFT_PRODUCT_KEYS[plan.internalEvent]),
            }));
        },
        isSelected(plan) {
            if ('value' in plan) {
                return this.selectedPlanId === plan.value;
            }
            return this.selectedPlanId === plan.productId;
        },
        handleSelectPlan(plan) {
            if ('value' in plan) {
                this.selectedPlanId = plan.value;
                return;
            }
            this.selectedPlanId = plan.productId;
        },
    },

};
</script>


<style lang="scss" src="./BegGiftingView.scss" />