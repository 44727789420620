<template>
  <div class="beg-form">
    <div class="beg-form__gun" />
    <h3 class="beg-form__title">
      {{ $t('giftVoicemod.formTitle') }}
    </h3>
    <div class="beg-form__info">
      <div class="beg-form__label">
        {{ $t('giftVoicemod.formInputEmailLabel') }}:
      </div>
      <div>{{ email }}</div>
    </div>
    <div class="beg-form__plan">
      <div class="beg-form__label">
        {{ $t('giftVoicemod.selectedPlan') }}
      </div>
      <div class="beg-form__product">
        <div>{{ selectedPlan.planTranslatedName }}</div>
        <div>{{ planPrice }}</div>
      </div>
    </div>
    <button
      class="beg-form__button"
      @click="handleSubmit"
    >
      {{ $t('giftVoicemod.formSubmitButton') }}
    </button>

    <div class="beg-form__terms">
      <a
        :href="giftLicensePrivacyPolicy"
        target="_blank"
        class="beg-form__link"
        rel="noopener noreferrer"
      >
        {{ $t('giftVoicemod.termsAndConditions') }}
      </a>
    </div>
  </div>
</template>

<script>
import { URL_CONSTANTS } from '@/externalURLs';
import { ROUTER_PATHS } from '@/router/paths';
import { metricsService } from  '@/metrics/metrics';
import { mParticleMetric } from '../../metrics/mparticle';

export default {
    name: 'BegFormComponent',
    props: {
        selectedPlan: {
            type: Object,
            default: null,
        },
        email: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            giftLicensePrivacyPolicy: URL_CONSTANTS.GIFT_LICENSE_PRIVACY_POLICY,
            recipientName: '',
            recipientEmail: '',
            isInvalidEmail: false,
            isInvalidName: false,
        };
    },
    computed: {
        planPrice() {
            return this.selectedPlan?.fullPrice || this.selectedPlan?.price;
        },
    },
    methods: {
        trackSubmit() {
            const eventData = {
                step: 'submit',
                product_name: this.selectedPlan.internalEvent,
                product_price: this.selectedPlan.fullPrice || this.selectedPlan.price,
                voicemod_system: 'account.voicemod.net',
                is_beg: true,
            };
            metricsService.trackEvent(eventData);
            mParticleMetric.track(
                mParticleMetric.EVENTS.GIFT_LICENSE, eventData, mParticleMetric.EVENT_TYPE.Transaction,
            );
        },
        handleSubmit() {
            this.trackSubmit();
            const checkoutRoute = this.$router.resolve({
                path: ROUTER_PATHS.CHECKOUT,
                query: {
                    productId: this.selectedPlan.productId,
                    giftingEmail: this.email,
                    isBeg: true,
                    payload: btoa(`${this.email}| |${this.selectedPlan.productId}`),
                },
            });
            window.open(checkoutRoute.href, '_blank');
        },
    },
};
</script>

<style lang="scss" src="./BegFormComponent.scss" />
